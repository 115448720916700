/* Config
------------------------------------------------------*/
var conf = {};
conf.shopskript = "/cgi-bin/shop/shop.pl";
conf.es_api_on = true; // auf ES zugreifen?
conf.url_es_login = "/cgi-bin/shop/shop.pl?aktion=set_es_session";
conf.url_es_search = "/cgi-bin/shop/shop.pl?aktion=groove_startsearch"; //"https://www.musikalienhandel.de::3010/V1/StartSearch/";
conf.url_recommend = "/cgi-bin/shop/shop.pl?aktion=recommend&FKM_ES_Artikelnummer=%s";
conf.url_ola = "/cgi-bin/shop/shop.pl?aktion=ola&articlenumber=%s";


conf.liveupdatecheck = false; //true oder false liveupdate aktivieren oder nicht
conf.liveupdateinterval = 10; // Sekundenintervall in dem der Server nach Liveupdates gefragt wird wenn conf.liveupdatecheck true ist
conf.url_liveupdatecheck = "/cgi-bin/shop/shop.pl?aktion=live&EurosoftNumber=%s";
conf.url_produktansicht_ajax = "/cgi-bin/shop/shop.pl?aktion=instrumentproduktansicht&ajaxresponse=1&FKM_ES_Artikelnummer=%s";

conf.url_recommend_collect_ajax = "/cgi-bin/shop/shop.pl?aktion=recommend&modus=collect";

conf.url_orderhistory_ajax = "/cgi-bin/shop/shop.pl?aktion=bestellhistorie&orderid=%s";


conf.url_menu_right = "/struktur/html/menue_right_ajax.php";
conf.url_menu_service = "/struktur/html/menue_service.php";
conf.url_menu_ueber_uns = "/struktur/html/menue_ueber_uns.php";

conf.versionfolder = "shop";

var urlmerkzettel = "/cgi-bin/shop/merkzettel/merkzettel.pl?";
var shopskript = "http://www.musikalienhandel.de/cgi-bin/shop/shop.pl";
var secureshopskript = shopskript;

merkzettel = {
	init:function(){
		$(".warenkorb:not(.nomerkzettel)").each(function(index,s){
    			
    			mz = {};
    			mz.merkzettelid = s.id+"_merkzettel";
    			mz.merkzettellink = s.href;
    			mz.artikelnummer = s.id.replace("warenkorb_link_","");
    			
    			console.log(mz);
    			//merkzettellink = merkzettellink.replace(/in_warenkorb_legen/,"auf_merkzettel_setzen");
    			//s.up().insert(Builder.node('a',{'class' : 'merkzettellink', href: merkzettellink, id:merkzettelid},'auf den Merkzettel'));
 
    	});
	},
	
	checkmerkzettelda:function(){
		// Gibts nen Merkzettel zu dieser Session? Dann zeigen wir den
		url = urlmerkzettel+"aktion=checkmerkzettelda";
		new Ajax.Request(url, {
			method:'get',
			onSuccess: function(transport){
				var response = transport.responseText || "no response text";
				exist = 0; 
				response.evalScripts();	
				if(exist == 1){ 
				
					/* Toolbar unten bauen, wenn nicht da*/
					if(!$('merkzettel')){
						var objBody = $$('body')[0];
						objBody.insert(Builder.node('div',{ 'class' : 'merkzettel', id:'merkzettel'}));
					}
					$('merkzettel').innerHTML = response;
					// setzt den fooer fuer ios nach unten
					if(ios()){ iosfooter()};
				}
				else{
					if($('merkzettel')){
						$('merkzettel').setStyle({display: 'none'});
					}
				}
			}
	
		});
	
	}
}
/*

function auf_merkzettel_setzen(id){
		url = urlmerkzettel+"aktion=eintrag_machen&FKM_ES_Artikelnummer="+id+"&modus=shortlist";

		new Ajax.Request(url, {
			method:'get',
			onSuccess: function(transport){
				var response = transport.responseText || "no response text";
				
				// nicht ausgeben! $('merkzettel').innerHTML = response; 
				checkmerkzettelda();
				return false;
			},
			onLoading: function(){
				Effect.Shake($('mztoolbar'),{duration:2.0,distance:5});
			},
			onFailure: function(){ 
				alert('Something went wrong...');
			},
			onComplete: function(){
				
			}
		});
}
function merkzettel_speichern_abschluss(id){
		var aktion = urlmerkzettel+"aktion=merkzettel_speichern_abschluss";
		var myhash = new Hash();
		myhash = $('merkzettelformular').serialize(getHash = true);// -> string
	
	
		new Ajax.Request(aktion, {
			method:'post',
			parameters: myhash,
			onSuccess: function(transport) {
					 var response = transport.responseText || "no response text";
					$('interstitialscreen').innerHTML = response; 
			}
		});
}

function merkzettel_speichern(id){
	 	var obj = { url: `${urlmerkzettel}aktion=merkzettel_speichernn&PM_Merkzettel=${id}}`, flat : "merkzettel" };
		tools.interstitital(obj);
}

function merkzettel_zeigen(id){
		
		var obj = { url: `${urlmerkzettel}aktion=merkzettel_zeigen&PM_Merkzettel=${id}}`, flat : "merkzettel" };
		tools.interstitital(obj);
}

function merkzettel_anmelden(){
	// empfehlen_anmeldeformular
	var aktion = urlmerkzettel+"aktion=merkzettel_anmelden";
	
	var myhash = new Hash();
	myhash = $('merkzettel_anmeldeformular').serialize(getHash = true);// -> string
	
	
	new Ajax.Request(aktion, {
		method:'post',
        parameters: myhash,
        onSuccess: function(transport) {
                 var response = transport.responseText || "no response text";
			 	$('interstitialscreen').innerHTML = response; 
		}
	});
}

function merkzettel_loeschen(id){
	var url = urlmerkzettel+"aktion=merkzettel_loeschen&FKM_ES_Artikelnummer="+id;
	new Ajax.Request(url, {
			method:'get',
			onSuccess: function(transport){
				var response = transport.responseText || "no response text";
				$('interstitialscreen').innerHTML = response; 
			},
			onLoading: function(){
			},
			onFailure: function(){ 
				
			},
			onComplete: function(){
				checkmerkzettelda();
			}
		});
}

function merkzettel_speichern_abschluss(){
	// empfehlen_anmeldeformular
	var aktion = urlmerkzettel+"aktion=merkzettel_speichern_abschluss";
	
	var myhash = new Hash();
	myhash = $('merkzettelformular').serialize(getHash = true);// -> string
	
	
	new Ajax.Request(aktion, {
		method:'post',
        parameters: myhash,
        onSuccess: function(transport) {
                 var response = transport.responseText || "no response text";
			 	$('interstitialscreen').innerHTML = response; 
		}
	});
}

function merkzettel_versenden(id){
	var query = $(id).serialize();// -> string
	
	var aktion = urlmerkzettel+query;
	
	new Ajax.Request(aktion, {
		method:'get',
        onSuccess: function(transport) {
                 var response = transport.responseText || "no response text";
			 	$('interstitialscreen').innerHTML = response; 
		}
	});
}
*/

globals = new Object;
globals.esSuchurl = "/shop/de/instrumente-zubehoer/suche/%s-;-41-;-0-;-";

/* Vars */
sprache = "de";
cockpitbereich = new Object;
cockpitbereich.instrumente = "Instrumente";
cockpitbereich.zubehoer = "Zubeh&ouml;r";
cockpitbereich.noten = "Noten";
cockpitbereich.i = "Instrumente";
cockpitbereich.z = "Zubeh&ouml;r";
cockpitbereich.n = "Noten";

cockpitbereich.menueinit = false;

produktbereich = new Object;
produktbereich.i = "instrumente";
produktbereich.z = "zubehoer";
produktbereich.n = "noten";

/* spinner */
var opts = {
    top: '1%', // Top position relative to parent in px
    left: '50%', // Left position relative to parent in px
    className: 'spinner' // The CSS class to assign to the spinner
};

var spinner = new Spinner(opts).spin();
var matcher = "##";


/* Main
------------------------------------------------------*/
$(document).ready(function() {

    //<!-- Lazy-Load -->
    bLazy = new Blazy();

    // getting the exact stockingamount and adjust shipping text.
    if ($("[data-fkm_id_lieferhindernis_overruled=121]").length) {
        //getStockInfo.init();
    }

    (function(window, undefined) {
        /*/ Establish Variables
        var
        	History = window.History, // Note: We are using a capital H instead of a lower h
        	State = History.getState(),
        	$log = $('#log');

        // Log Initial State
        History.log('initial:', State.data, State.title, State.url);

        // Bind to State Change
        History.Adapter.bind(window,'statechange',function(){ // Note: We are using statechange instead of popstate
        	// Log the State
        	var State = History.getState(); // Note: We are using History.getState() instead of event.state
        	History.log('statechange:', State.data, State.title, State.url);
        });

        // Bind to Pop Change
        History.Adapter.bind(window,'popstate',function(e){ // Note: We are using statechange instead of popstate
        	// Log the State
        	var State = History.getState(); // Note: We are using History.getState() instead of event.state
        	History.log('popstate:',e.state, State.data, State.title, State.url);

        });*/

    })(window);


    $('html').removeClass('no-js');

    detect.breakpoint();

    if (cockpitbereich.menueinit == false) {
        cockpitbereich.menueinit = true;
        menue.init();
    }

    warenkorb.einfaerben();

    warenkorb.auswerten({ "ajaxresponse": 1 });


    gallery.init(gallery.showgallery);

    warenkorb.init(); // tbd nur auf warenkorbseiten

    programm.edithint();

    $('body').click(function() {
        if ($('#cockpit_suchbegriff').val()) {
            $('#cockpit_suchbegriff').removeClass('empty');
            $('#cockpit_suchbegriff').attr('placeholder', '');
        }
    });
    if ($('#cockpit_suchbegriff').val()) { $('#cockpit_suchbegriff').removeClass('empty'); }


    /* gabs hier ne ajax aktion ? */
    /* dann fummeln wir die wieder in die Seite */


    if (location.href.match(matcher) != null) {
        hrefloc = (location.href.split(matcher))[0];
        ajaxquery = (location.href.split(matcher))[1];
        url = conf.shopskript + "?" + ajaxquery;

        hash = new Object();
        data = ajaxquery.split("&");
        i = 0;
        while (i < data.length) {
            key = (data[i].split("="))[0];
            value = (data[i].split("="))[1];
            hash[key] = value;
            i++;

        }
        $("cockpit_suchbegriff").value = decodeURI(hash['suchbegriff']);

        create_outputelement.create();

        var obj = {};
        obj.url = url;
        ez.request(obj);
    }




    // live-update
    /*if(conf.liveupdatecheck && globvars.FKM_ES_Artikelnummer && $('#produktansichtinstrument').length){
		t = conf.liveupdateinterval * 1000
		setInterval(function() {
			url =  conf.url_liveupdatecheck;
			url = url.replace(/%s/,globvars.FKM_ES_Artikelnummer);
         	$.ajax({
			type: "GET",
			url: url,
			dataType: 'json',
			success: function(data){
				if((data.I_Liveupdate || globvars.I_Liveupdate) && globvars.TS_Aenderungsdatum_zusatz != data.TS_Aenderungsdatum_zusatz){
					globvars.TS_Aenderungsdatum_zusatz = data.TS_Aenderungsdatum_zusatz;
					globvars.I_Liveupdate = data.I_Liveupdate;
					obj = {'FKM_ES_Artikelnummer' : globvars.FKM_ES_Artikelnummer};
					productdata.getdata(obj);
				}
			}
		});
    	}, t);
	}*/
    // live-update EurosoftNumber
    if (conf.liveupdatecheck && globvars.EurosoftNumber && $('#produktansichtinstrument').length) {
        t = conf.liveupdateinterval * 1000;
        bootboxvisible = false;
        var interval = setInterval(function() {
            url = conf.url_liveupdatecheck;
            url = url.replace(/%s/, globvars.EurosoftNumber);
            $.ajax({
                type: "GET",
                url: url,
                dataType: 'json',
                success: function(data) {

                    if (data.D_Preis != globvars.D_Preis && bootboxvisible == false && globvars.D_Preis) {

                        bootbox.dialog({
                            message: "Es gab eine Aktualisierung der Preise auf musikalienhandel.de.",
                            title: "Preisupdate",
                            buttons: {
                                success: {
                                    label: "Jetzt aktuelle Preise laden",
                                    className: "btn-success",
                                    callback: function() {
                                        var url = location.href + "?" + Math.random(1);
                                        window.location = url;
                                        //location.reload();
                                    }
                                }
                            }
                        });
                        bootboxvisible = true;
                        clearInterval(interval);
                    }



                    /*if((data.I_Liveupdate || globvars.I_Liveupdate) && globvars.TS_Aenderungsdatum_zusatz != data.TS_Aenderungsdatum_zusatz){
                    	globvars.TS_Aenderungsdatum_zusatz = data.TS_Aenderungsdatum_zusatz;
                    	globvars.I_Liveupdate = data.I_Liveupdate;
                    	obj = {'EurosoftNumber' : globvars.EurosoftNumber};
                    	productdata.getdata(obj);
                    }*/
                }
            });
        }, t);



    }

    /* Tooltipp Versandhinweis Instrumente */
    if ($('#produktansichtinstrument').length) {
        $("[data-availability-code='1']").each(function() {
            this.innerHTML = "<span style='cursor:pointer' data-toggle='tooltip' title='Sofort versandfertig beim Großhändler oder Hersteller, deren Lager- und Logistikzentren wir ressourcenschonend mitnutzen - Qualitätskontrolle durch musikalienhandel.de!'><u>" + this.innerHTML + "</u></span>";
            $('[data-toggle="tooltip"]').tooltip({ trigger: 'click' });
            $('[data-toggle="tooltip"]').mouseout(function() { $(this).tooltip('hide'); });
        });
    }


    /* Tooltipp Versandhinweis Instrumente */
    if ($('#produktansichtnoten').length >= 0) {
        $("[data-availability-code='32'],[data-availability-code='0'],[data-availability-code='121'],[data-availability-code='899']").each(function() {
            this.innerHTML = "<span style='cursor:pointer' data-toggle='tooltip' title='Sofort versandfertig beim Großhändler oder Verlag, dessen Lager- und Logistikzentren wir ressourcenschonend mitnutzen - Qualitätskontrolle durch musikalienhandel.de!'><u>" + this.innerHTML + "</u></span>";
            $('[data-toggle="tooltip"]').tooltip({ trigger: 'click' });
            $('[data-toggle="tooltip"]').mouseout(function() { $(this).tooltip('hide'); });
        });
    }

    //merkzettel.init();
    finden.initOrder(); // Initialisieren des Sortforms


    ez.countArea();


    // leeres Formular nicht abschicken
    $("#cockpitformular").submit(function(event) {
        if ($("#cockpit_suchbegriff").val() == "") {
            $("#cockpit_suchbegriff").attr("placeholder", "Suchbegriff eingeben");
            event.preventDefault();
        }
    });

    if (globvars.FKM_ES_Artikelnummer) {

        recommend.init();
        recommend.display();
    }


    orderhistory.init();

});


menue = {
    init: function() {


        url = conf.url_menu_right;

        $.ajax({
            type: "GET",
            url: url,

            success: function(data) {

                $("#menue_right").append(data);
                cockpitbereich.menueinit = true;

            }
        });

    },
    getdata: function(obj) {

    }
}

productdata = {
    init: function() {

    },
    getdata: function(obj) {
        url = conf.url_produktansicht_ajax;
        url = url.replace(/%s/, globvars.FKM_ES_Artikelnummer);

        $.ajax({
            type: "GET",
            url: url,

            success: function(data) {
                $("#produktansichtinstrument").html(data);
            }
        });
    }
}


/* In Warenkorb legen
function in_warenkorb_legen(FKM_ES_Artikelnummer,flag,mode){
	warenkorb.einlegen(FKM_ES_Artikelnummer,flag,mode);
}*/

warenkorb = {
    /* Buttons mit Funktionen belegen */
    init: function() {
        $(".warenkorbaktualisieren").click(function() {

            warenkorb.aktualisieren($(this));
        });
        $(".warenkorbartikelentfernen").click(function() {

            warenkorb.artikelentfernen($(this));
        });
    },
    einlegen: function(obj) {
        var sprache = lang.getlang();
        if (obj.EurosoftNumber == undefined) { obj.EurosoftNumber = ""; }
        var url = conf.shopskript + "?aktion=in_warenkorb_legen&sprache=" + sprache + "&FKM_ES_Artikelnummer=" + obj.FKM_ES_Artikelnummer + "&EurosoftNumber=" + obj.EurosoftNumber + "&no-gzip=1&ajax=1&" + Math.random();

        $.ajax({
            type: "GET",
            url: url,
            success: function(data) {

                if ($('#warenkorbliste').length) {
                    $('#warenkorbliste').html(data);
                    $('#cartlist').html(data);

                    warenkorb.updatesum(wkjson);

                    warenkorb.einfaerben();
                } else {

                    /* ggf reload zBsp. am Checkout mit Candy */
                    if (obj.mode == "reload") {
                        obj.serializeform = false; // damit das form nicht ausgewertet wird.
                        warenkorb.aktualisieren(obj);
                    }
                }
            },
            onLoading: function() {
                if ($('#warenkorbliste')) {
                    //Effect.Shake($('warenkorbliste'),{duration:2.0,distance:5})
                }
            }
        });

        /*if(flag){
        	var url = conf.shopskript+"?aktion=count&modus="+flag+"&FKM_ES_Artikelnummer="+obj.FKM_ES_Artikelnummer+"&"+Math.random();
        	$.ajax({
        		type: "GET",
        		url: url,
        		success: function(data){
        		}
        	});
        }	*/
    },
    /* Wenn z.Bsp. im Checkout die Stückzahl geändert wird */
    aktualisieren: function(obj) {

        var datastring = $("#warenkorbformular").serializeArray();
        if (obj.serializeform == false) { datastring = ""; }
        var url = conf.shopskript + "?aktion=warenkorb_aktualisieren&ajax=1";
        $.ajax({
            type: "POST",
            url: url,
            data: datastring,
            success: function(data) {
                //var obj = jQuery.parseJSON(data); if the dataType is not specified as json uncomment this
                // do what ever you want with the server response
                $("#warenkorbbuehne").html(data);
                warenkorb.updatesum(wkjson);
                warenkorb.auswerten({ "ajaxresponse": 1 });
                warenkorb.init();

            },
            error: function() {
                // alert('error handing here');
            }
        });
    },
    /* Warenkorb - kleine Liste holen */
    auswerten: function(obj) {
        var url = conf.shopskript + "?aktion=warenkorb_auswerten&ajax=1&modus=" + obj.modus + "&ajaxresponse=" + obj.ajaxresponse;
        $.ajax({
            type: "GET",
            url: url,
            success: function(data) {
                //var obj = jQuery.parseJSON(data); if the dataType is not specified as json uncomment this
                // do what ever you want with the server response
                $('#cartlist').html(data);
                warenkorb.updatesum(wkjson);

            },
            error: function() {
                //alert('error handing here');
            }
        });
    },
    artikelentfernen: function(obj) {
        var el = $(obj).parent().parent().children()[0];
        $(el).val(0);
        var obj = {};
        warenkorb.aktualisieren(obj);
        warenkorb.init();
    },
    updatesum: function(wkjson) {
        //console.log(wkjson.gesamtsumme);
        summe = "<a href=\"/" + conf.versionfolder + "/warenkorb\">" + wkjson.gesamtsumme + " €</a>";
        $('#cart_total').html(summe);

    },
    einfaerben: function() {

        // Auf Trefferseite
        $(".warenkorbliste").each(function() {
            var $id = $(this).attr('id').replace("warenkorbliste_", "");
            //console.log("id"+$id);
            $('#trefferliste_' + $id).addClass("im_warenkorb");
            $('#warenkorblink_' + $id).html("mehr in den<br /> Warenkorb legen");
        });



    }
}


getStockInfo = {
    init: function(obj) {

        var url = conf.shopskript + "?aktion=get_stock_batch";

        var mgs = [];

        $("[data-fkm_id_lieferhindernis_overruled=121]").each(function() {
            mgs.push($(this).data('mgs_nr'));
        }).promise().done(function() {

            data = {};
            data.mgs = mgs.join(",");

            $.ajax({
                type: "POST",
                url: url,
                data: data,
                success: function(response) {

                    var obj = JSON.parse(response);

                    // if inStock < 3 - change the Wording for Shipping
                    $.each(obj, function(key, value) {
                        if (value == 0) {
                            var shippingtime = $("[data-mgs_nr=" + key + "]").attr("data-i_deliverytime");

                            if (shippingtime === undefined) { shippingtime = 10; }
                            $("[data-mgs_nr=" + key + "]").html("Lieferzeit bis zu " + shippingtime + " Tage<br><small class=fussnote>Aktueller Lagerbestand&nbsp;" + value + "</small>");

                            if ($("#tooltip_shipping").length) {
                                $("#tooltip_shipping").hide();
                            }
                        } else if (value > 0 && value < 6) {

                            var html = $("[data-mgs_nr=" + key + "]").html();
                            html += "<br><small class=fussnote>Nur noch " + value + " Stück auf Lager bei unserem Großhändler.</small>";
                            $("[data-mgs_nr=" + key + "]").html(html);

                        }

                    });

                }
            });
        });
    }
}




orderhistory = {
    init: function(obj) {

        $("[data-orderhistory]").each(function() {



            $(this).click(function(e) {

                e.preventDefault();

                data = {};
                data.orderid = $(this).attr('data-orderhistory');

                if ($(this).hasClass("isvisible")) {
                    $(this).removeClass("isvisible");

                    $(this).find("i").removeClass("fa-chevron-up");
                    $(this).find("i").addClass("fa-chevron-down");

                    $("[data-orderlist=" + orderid + "]").remove();
                } else {


                    $(this).addClass("isvisible");

                    $(this).find("i").removeClass("fa-chevron-down");
                    $(this).find("i").addClass("fa-chevron-up");


                    orderhistory.display(data);
                }

            });

        });
    },
    display: function(data) {
        console.log("ddd");

        orderid = data.orderid;
        $.ajax({
            type: "GET",
            url: conf.url_orderhistory_ajax.replace(/%s/g, orderid),
            success: function(data) {


                // insert in dom

                $("[data-orderhistory=" + orderid + "]").after(data);

                bLazy.revalidate();
            },
            beforeSend: function() {},
            complete: function() {

            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(xhr.status);
                console.log(thrownError);
            }
        });
    }
}

recommend = {
    init: function(obj) {

        $("a[data-recommendationtype]").each(function() {

            $(this).click(function(e) {
                e.preventDefault();

                data = {};
                data.recommendationtype = $(this).attr('data-recommendationtype');
                data.recommendationoriginid = $(this).attr('data-recommendationoriginid');
                data.recommendationid = $(this).attr('data-recommendationid');
                data.recommendationvalue = $(this).attr('data-recommendationvalue');
                data.S_Kennung = globvars.S_Kennung;
                data.href = $(this).attr('href');

                $.ajax({
                    type: "POST",
                    url: conf.url_recommend_collect_ajax,
                    data: data,
                    success: function(response) {
                        // console.log(data)
                        load(data);
                    },
                    // dont want to break the user journey
                    error: function() {
                        location.href = data.href;
                    }
                });


                function load(data) {
                    location.href = data.href;
                }
            });

        });
    },
    display: function() {
        $.ajax({
            type: "GET",
            url: conf.url_recommend.replace(/%s/g, globvars.FKM_ES_Artikelnummer),
            success: function(data) {


                // insert in dom

                $("#recommendation").append(data);

                bLazy.revalidate();
            },
            beforeSend: function() {},
            complete: function() {
                recommend.init();
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(xhr.status);
                console.log(thrownError);
            }
        });
    }
}

/* Alles zur Suche */
var finden = new Object();
var checksum = ""; // um sich selbstueberholende Suche zu vermeiden
var suche_check = "";
var timecheck = +new Date();

function sleep_until(seconds) {
    var max_sec = new Date().getTime();
    while (new Date() < max_sec + seconds * 1000) {}
    return true;
}


finden = {
    suchen: function(obj) {
        var sprache = lang.getlang();

        if (($("#cockpit_suchbegriff").val()) && ($("#cockpit_suchbegriff").val() != suche_check)) {

            var suchwert = $("#cockpit_suchbegriff").val();
            suche_check = suchwert;

            if (suchwert.length > 1) {
                /* Outputelement bauen */
                create_outputelement.create();

                checksum = finden.checksummer(suchwert);

                var url = conf.shopskript + "?sprache=" + sprache + "&format=EZ&" + $("#cockpitformular").serialize() + "&checksum=" + checksum;

                var cockpitbereich = $("#cockpit_select").val();
                if (cockpitbereich == undefined) { cockpitbereich = "noten"; }

                if (cockpitbereich == "instrumente") {
                    // /cgi-bin/shop/shop.pl?sprache=$1&aktion=gewasearch&cockpit=instrumente&suchstring=$2&catID=$3&pageID=$4&PKM_GEWA_Kategorie=$5 [L,QSA]
                    url = globals.esSuchurl.replace(/%s/, suchwert);
                    var query = $("#cockpitformular").serialize();
                    query = query.replace(/aktion=suchen/, "");
                    url += "?sprache=" + sprache + "&format=EZ&" + query + "&checksum=" + checksum;
                    ez.request({ "url": url });
                } else {
                    /* die eigentliche anfrage */
                    ez.request({ "url": url });
                }


                suche_check = $("#cockpit_suchbegriff").val();

            }
        }
        return false;
    },
    clear: function(wert) {

    },
    blur: function(wert) {

    },
    initOrder: function() {
        sortfilter = {};
        if ($("#sortform").length) {
            $("#sortform").change(function() {

                sortfilter = $("#sortform").serializeArray();

                $(sortfilter).each(function(i, field) {
                    sortfilter[field.name] = field.value;
                });

                $('#orderby option:selected').each(function() {
                    sortfilter['orderby'] = $(this).val();
                });


                var $urlfragment = globals.esSuchurl;
                $urlfragment = $urlfragment.replace(/%s/, sortfilter['searchstring']);
                $urlfragment = $urlfragment + "-;-" + sortfilter['orderby'];

                history.pushState({ id: 'SOME ID' }, '', $urlfragment);
                location.reload();
            });
        }
    },
    checksummer: function(klarText) {
        var checksum = 0;
        for (var i = 0; i < klarText.length; i++) {
            var encodedChar = klarText.charCodeAt(i);
            checksum = encodedChar + checksum;
        }
        return checksum;
    }
}



/* Echzeitsuche */
var ez = new Object();
ez = {
    sequenz_navi: function(url) {
        url += "&breakpoint=" + globals.breakpoint;
        ez.request({ "url": url });
        return false;
    },
    locked: false,

    external_count: function(url, search, catID) {
        if (!globvars.SessionID) {
            console.log("Keine SessionID verfügbar!");
        }
        if (ez.locked) return;
        ez.locked = true;
        $('#SearchListView').empty();

        /*
          not supported by jquery 3.6.0 - ss 2021-07-06
          $.post(url + 'html', {search: search, catID: catID, S_Kennung : globvars.S_Kennung, SessionID : globvars.SessionID}).success(function(data) {
        	$('#zubehoer-gewa-teaser-container').remove();

        	$('#trefferliste_ez_navi_button').empty();
        	$('#trefferliste_ez_navi_button').prepend(data);

        	$("#spinnerarea").empty();
        }).complete(function() {
        	ez.locked = false;
        });*/

        // Assign handlers immediately after making the request,
        // and remember the jqxhr object for this request
        var jqxhr = $.post(url + 'html', { search: search, catID: catID, S_Kennung: globvars.S_Kennung, SessionID: globvars.SessionID }, function() {

            })
            .done(function(data) {
                console.log("ez search ist done!" + data + "<---");
                $('#zubehoer-gewa-teaser-container').remove();
                $('#trefferliste_ez_navi_button').empty();
                $('#trefferliste_ez_navi_button').prepend(data);
                $("#spinnerarea").empty();
            })
            .fail(function(data) {
                console.log("error ez" + data);
            })
            .always(function() {
                ez.locked = false;
            });





    },

    // counts hits of instruments or noten
    countArea: function(resjson) {

        if (typeof resjson == "undefined") { resjson = {}; }
        if (resjson.checksum == null) { resjson.checksum = ""; }
        var cockpitbereich = $("#cockpit_select").val();
        if (cockpitbereich == undefined) { cockpitbereich = "noten"; }

        if ($("#cockpit_suchbegriff").val() != "") {

            create_outputelement.create();

            if (cockpitbereich == "instrumente") {
                nurl = "/cgi-bin/shop/shop.pl?aktion=suchen&cockpit=noten&format=JSON&onlycount=1&cockpit=noten&suchbegriff=" + $("#cockpit_suchbegriff").val();
                if (resjson.checksum) {
                    nurl += "&checksum=" + resjson.checksum_response;
                }
                nresponseJSON = ez.count(nurl, "n");
            } else if (cockpitbereich == "noten") {
                if (conf.es_api_on == true) {
                    ez.external_count(conf.url_es_search, $("#cockpit_suchbegriff").val(), 41);
                }
            }
        }
    },
    count: function(url, bereich) {

        $.ajax({
            type: "GET",
            url: url,
            success: function(data) {
                var json = $.parseJSON(" " + data + " ");
                var id = json.checksum_response + bereich;

                url = url.replace(/&format=JSON&onlycount=1/, '');
                url = decodeURI(url);


                url = "/shop/" + sprache + "/" + produktbereich[bereich] + "/suche/" + json.suchbegriff + "/"; // fuers Tracking


                text = "<strong>" + cockpitbereich[bereich] + "</strong>: " + json.trefferaz + "</strong> Treffer";

                if (json.trefferaz > 0) {
                    text = "<div class=\"btn btn-sm resultlinkcontainer\"><a class=\"resultlink\" href=\"" + url + "\">" + text + "</a></div>";
                } else {
                    text = "<div class=\"btn btn-sm resultlinkcontainer disabled\"><a class=\" resultlink\" href=\"#\">" + text + "</a></div>";
                }

                text = '<div id="noten-teaser-container" data-cockpitbereich="d" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">' + text + '</div>';


                if (json.trefferaz > 0) {
                    //$("#"+id).empty();
                    //$("#"+id).append(divel);



                    $("#trefferliste_ez_navi_button").empty();
                    $("#trefferliste_ez_navi_button").append($(text));

                }

                $("#spinnerarea").empty();
            }
        });
    },
    request: function(obj) {
        obj.url += "&breakpoint=" + globals.breakpoint;
        $.ajax({
            type: "GET",
            url: obj.url,

            success: function(data) {
                resjson = "";
                antwort = "";
                var classes = " col-lg-12 col-md-12 col-sm-12 col-xs-12 ";

                /* Evaluate JS */
                tools.managescripts(data);

                if (resjson.trefferaz == 0) {

                    besetzung = false;
                    kategorie = false;

                    if (resjson.S_Besetzung) {
                        besetzung = " in der Besetzung '" + resjson.S_Besetzung + "' ";
                    }

                    if (resjson.PKM_Gattung_Besetzung) {
                        kategorie = " in " + $('[data-PKM_Gattung_Besetzung]').html();
                    }
                    if (resjson.PKM_Klasse_Besetzung) {
                        kategorie = " in " + $('[data-PKM_Klasse_Besetzung]').html();
                    }
                    if (resjson.PKM_Besetzung && besetzung == false) {
                        kategorie = " in dieser Kategorie ";
                    }

                    if (besetzung == false) {
                        besetzung = "";
                    }

                    if (kategorie == false) {
                        kategorie = "";
                    }

                    antwort = "<div  class=\"" + classes + " btn\">Ihre Suche nach '" + $("#cockpit_suchbegriff").val() + "' im Bereich " + cockpitbereich[resjson.cockpit] + besetzung + kategorie + " f&uuml;hrte zu keinem Treffer.<br/>" + resjson.alternative_html + "</div>";
                }

                //if(resjson.cockpit == "noten"){
                antwort += '<div id="' + resjson.checksum_response + 'n" class="' + classes + '" data-cockpitbereich="n">&nbsp;</div>';
                data = antwort + data;

                if ((finden.checksummer($("#cockpit_suchbegriff").val()) == resjson.checksum_response) || (obj.mode)) {


                    $("#trefferliste_ez").html(data);
                    //$('#trefferliste_ez').removeClass('spinner');

                    /* Falls noch ein oldSchoolliste steht */
                    if ($("#trefferliste")) {
                        //$("#trefferliste").html(); // leeren
                    }
                    var urlfrag = (obj.url.split("?"))[1];
                    //History.pushState({data:urlfrag,search:"xx"+Math.random()}, $("#cockpit_suchbegriff").val(),  "?"+urlfrag);
                    finden.initOrder(); /* Sortform initialisieren */
                    warenkorb.einfaerben(); /* Warenkorb */

                }
               // getStockInfo.init();
                bLazy.revalidate();
            },
            beforeSend: function() {

                $('#es_api_output').html("");

                if ($('#wraptrefferproduktansicht')) {
                    $('#wraptrefferproduktansicht').html("");
                }

            },
            // Ob erfolgreich oder nicht, sollte am Ende
            // der Inhalt wieder eingeblendet werden
            complete: function() {
                ez.countArea(resjson);
            },
            error: function(xhr, ajaxOptions, thrownError) { console.log(xhr.status);
                console.log(thrownError); }
        });
    }
}


/* Erstellen des Ausgabeelements für Ajax */
var create_outputelement = new Object();
create_outputelement = {

    create: function(obj) {

        /* HTML bauen, aber nur wenns das noch nicht gibt*/

        /* The spinnerArea */
        if (!$('#spinnerarea').length) {
            tell.me("#spinnerarea wurde erstellt");
            $('#output_suchergebnis').append('<div id="spinnerarea"></div>');
        }

        /* The bigbutton for results in other areas */
        if (!$('#trefferliste_ez_navi_button').length) {
            tell.me("#trefferliste_ez_navi_button wurde erstellt");
            $('#output_suchergebnis').append('<div id="trefferliste_ez_navi_button" class="trefferliste_ez_navi row" ></div>');
        }

        /* The divs for the resultarea */
        if (!$('#trefferliste_ez').length) {
            tell.me("#trefferliste_ez wurde erstellt");
            $('#output_suchergebnis').append('<div id="trefferliste_ez"></div>');
        }

        $("#spinnerarea").append(spinner.el);

    }
}



gallery = {
    init: function(callback) {
        var urls = new Array();


        $('[data-blueimp] img.gallery').each(function() {

            var $this = $(this);

            var unique_id = "gallery_" + Math.random(100); //+$.md5($(this).attr('src'));

            $this.wrap('<a href="' + $(this).data('xl-img') + '" class="gallery-link" title="" ></a>'); // a tag um das bild legen
            $gallerylink = $this.parent('.gallery-link'); // a-tag im dom selektieren


            urls.push($gallerylink);


        });

        callback(urls);

    },
    showgallery: function(val) {

        $('body').append('<!-- The Bootstrap Image Gallery lightbox --><div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls "><div class="slides"></div><h3 class="title"></h3><a class="prev"><i class="fa fa-arrow-left"></i></a><a class="next"><i class="fa fa-arrow-right"></i></a><a class="close"><i class="fa fa-close"></i></a><a class="play-pause"></a><ol class="indicator"></ol></div>');


        // KT - 15.03.2018 - Eventhandler muss hinzugefügt werden, damit blueimp getriggert wird und nicht das Bild in einer neuen Seite geladen wird.
        if (document.querySelectorAll('[data-blueimp="1"]').length > 0) {
            var list = document.querySelectorAll('a.gallery-link');

            for (i = 0, len = list.length; i < len; i++) {
                list[i].onclick = function(event) {

                    options = { index: this, event: event },
                        blueimp.Gallery(list, options);
                };
            }

            /*.onclick = function (event) {
            	event = event || window.event;
            	var target = event.target || event.srcElement,
            		link = target.src ? target.parentNode : target,
            		options = {index: link, event: event},
            		links = this.getElementsByTagName('a');

            		console.log(event);

            	blueimp.Gallery(links, options);
            };*/
        }

        //options = {'carousel': true, 'slideshowInterval': 5000000};
        //blueimp.Gallery(val, options);
    }
}


editieren = {
    speichern: function(obj) {

        obj.url = conf.shopskript + "?aktion=editieren_speichern";

        var data = $("#" + obj.formid).serialize();

        $.ajax({
            type: "POST",
            url: obj.url,
            data: data,
            success: function(data) {
                $('#' + obj.el).html(data);
            }
        });


    },
    einreichen_freigabe: function(obj) {

        obj.url = conf.shopskript + "?aktion=einreichen_zur_freigabe";

        var data = $("#" + obj.formid).serialize();

        $.ajax({
            type: "POST",
            url: obj.url,
            data: data,
            success: function(data) {
                $('#' + obj.el).html(data);
            }
        });



    },
    indicator_clear: function(obj) {
        //$("#"+obj.id).text() = "";
        //console.log(obj);
    }
}

/* Sprachverwaltung */
var lang = new Object();
lang = {
    getlang: function(obj) {
        return "de";
    }
}

var programm = new Object();
programm = {
    edithint: function() {

        text = new Object;
        text.notencharlength = 400;
        text.instrumentecharlength = 400;
        text.charlength = text.notencharlength;

        text.notenurl = "/noten/editorenprogramm";
        text.instrumenteurl = "/instrumente/editorenprogramm";
        text.url = text.notenurl;

        if ($('#produktansichtinstrument').length) {
            text.charlength = text.instrumentecharlength;
            text.url = text.notenurl;
        }

        var url = text.url;


        var el;
        var angemeldet = 0;
        /* nicht angemeldet */
        if ($('#T_Produktinfo_hdm').length >= 1) { el = $('#T_Produktinfo_hdm'); }
        /*angemeldet */
        if ($('#T_Produktinfo_hdm_edit').length >= 1) { el = $('#T_Produktinfo_hdm_edit');
            angemeldet = 1; }

        /* Nicht angemeldet */
        if (el) {
            var text1 = "";
            var text2 = "";
            var check = 0;



            if (el.text().length < 10) {
                text1 = "Schade, hier fehlt ein Begleittext zum Artikel.<br />\n";
                check = 1;
            } else if (el.text().length < text.charlength) {
                text1 = "Der Begleittext zu diesem Artikel ist verdammt kurz.<br />\n";
                check = 1;
            }

            text2 = "Jetzt Geld verdienen und eigenen Text hier hochladen!";

            var element = $('<div><span>' + text1 + '</span><strong>' + text2 + '</strong><br /><a href="' + url + '">Infos zur Vergütung und unserem Editorenprogramm</a></div>').attr('class', 'edithint well');

            if (angemeldet) {
                text2 = "Jetzt hier klicken und Text hochladen!";
                element = $('<div><span>' + text1 + '</span><br /><span class="btn">' + text2 + '</span></div>').attr('class', 'edithint well');
            }
            /*var element = Builder.node('span',{'class' : 'edithint'},[
            		Builder.node('span',text1),
            		Builder.node('strong',text2),
            		Builder.node('a',{href : url},'Infos zur Verguetung und unserem Editorenprogramm')
            	]
            );*/

            if (check) {
                el.append(element); //$('#T_Produktinfo_hdm').insert({top : element});

                if (angemeldet) {
                    el.click(function() {
                        var obj = { modus: 'editor', FKM_ES_Artikelnummer: globvars.FKM_ES_Artikelnummer };
                        console.log(obj);
                        tools.interstitial(obj);
                    });
                }
            }
        }


    }
}

var tools = new Object();
tools = {
    /* Wertet JS aus Html-Ajax-Responses aus */
    managescripts: function(strcode) {
        var scripts = new Array(); // Array which will store the script's code

        // Strip out tags
        while (strcode.indexOf("<script") > -1 || strcode.indexOf("</script") > -1) {
            var s = strcode.indexOf("<script");
            var s_e = strcode.indexOf(">", s);
            var e = strcode.indexOf("</script", s);
            var e_e = strcode.indexOf(">", e);

            // Add to scripts array
            scripts.push(strcode.substring(s_e + 1, e));
            // Strip from strcode
            strcode = strcode.substring(0, s) + strcode.substring(e_e + 1);
        }

        // Loop through every script collected and eval it
        for (var i = 0; i < scripts.length; i++) {
            try {
                eval(scripts[i]);
            } catch (ex) {
                // do what you want here when a script fails
            }
        }
    },
    req: function(obj) {

        $.ajax({
            type: "GET",
            url: obj.url,
            success: function(data) {
                //console.log(obj.el);
                $('#' + obj.el).html(data);
            }
        });

    },
    collectform: function(obj) {
        data = $("#" + obj.formid).serialize();
        $.ajax({
            type: "POST",
            url: obj.url,
            data: data,
            success: function(data) {
                $('#' + obj.el).html(data);
            }
        });
    },
    videoplayer: function(obj) {
        var url = "http://www.youtube.com/watch?v=" + obj.id;
        location.href = url;
    },
    interstitial: function(obj) {
        if (obj.modus == "editor") {
            obj.url = conf.shopskript + "?aktion=editieren&FKM_ES_Artikelnummer=" + obj.FKM_ES_Artikelnummer;
            console.log(obj.url);
        } else if (obj.modus == "editorview") {
            obj.url = conf.shopskript + "?aktion=editorview&PKM_Upload_Web" + obj.PKM_Upload_Web;
        }



        $('#myModal').load(obj.url);

        $('#myModal').modal({ show: true });
    }

}



eventracking = {
    init: function() {

        if (typeof ga == 'function') {

            // events
            $('#B_Newsletter').on('click', function() {
                //if(this.checked){
                //ga('send', 'event', 'newsletter', 'click',"bestellt",1);
                //}
            });
        }

    }
}


/* Detections */
detect = {
    breakpoint: function() {
        var dsize = ['lg', 'md', 'sm', 'xs'];
        for (var i = dsize.length - 1; i >= 0; i--) {

            // Need to add &nbsp; for Chrome. Works fine in Firefox/Safari/Opera without it.
            // Chrome seem to have an issue with empty div's
            $el = $('<div id="sizeTest" class="hidden-' + dsize[i] + '">&nbsp;</div>');
            $el.appendTo($('body'));
            $('#sizeTest').css({ "position": "absolute", "top": "-100%", "left": "-100%", "width": "100%" });

            if ($el.is(':hidden')) {
                $el.remove();
                globals.breakpoint = dsize[i];
                return globals.breakpoint;
            } else {
                $el.remove();
            }
        }

        globals.breakpoint = "unknown";
        return globals.breakpoint;
    },
    device: function() {

        globals.device = "other";
        if (navigator.userAgent.match(/iPhone/i)) { globals.device = "iphone"; }
        if (navigator.userAgent.match(/iPad/i)) { globals.device = "ipad"; }
        if (navigator.userAgent.match(/Android/i)) { globals.device = "android"; }
        return globals.device;
    }
}

var zahlungsart = new Object();
zahlungsart = {
    init: function(_wert) {

        //document.getElementById("zahlungsartausland").style.display='none';


        $("[data-zahlungsartid]").each(function() {
            id = $(this).attr("id");

            if ($(this).data('zahlungsartid') == _wert) {
                document.getElementById(id).style.display = 'block';
            } else {
                document.getElementById(id).style.display = 'none';
            }
            
            
            
            
        }).promise().done(function() {
            // Ausland  
            if ($("#I_Id_Land").val() != 1) {
                document.getElementById("zahlungsart").style.display = 'none';
                document.getElementById("zahlungsartausland").style.display = 'block';
            } else {
                document.getElementById("zahlungsart").style.display = 'block';
                document.getElementById("zahlungsartausland").style.display = 'none';
            }
        });

        // set eventlistener
        $("[data-field=zahlungsart]").change(function(i) {
            zahlungsart.init(this.value);
        });


        $("#I_Id_Land").change(function(i) {
            if (this.value != 1) {
                document.getElementById("zahlungsart").style.display = 'none';
                document.getElementById("zahlungsartausland").style.display = 'block';
            } else {
                document.getElementById("zahlungsart").style.display = 'block';
                document.getElementById("zahlungsartausland").style.display = 'none';
            }
        });

    },
    /*initausland:function(_wert){ 
	  $("[data-zahlungsartid").each(function(){
	      id = $(this).attr("id");
	      console.log(id+"_"+$(this).data('zahlungsartid')+"_"+_wert)
	      if($(this).data('zahlungsartid') === _wert){
	        document.getElementById(id).style.display='block';
	      }
	      else{
	        document.getElementById(id).style.display='none';
	      }
	 });
	}*/
    change: function() {
        zahlungsart.init(this.value);
    }



}

/* Debug */
var tell = new Object();
tell = {
    me: function(i) {
        console.log(i);
    }
}


function mw_zahlungsart_ausland(_wert) {
    mw_zahlungsart(_wert);
}


/*function mw_zahlungsart(_wert){ 
	    $("[data-zahlungsartid").each(function(){
	      id = $(this).attr("id");
	      console.log(id+":::"+$(this).data('zahlungsartid'));
	      if($(this).data('zahlungsartid') === _wert){
	        document.getElementById(id).style.display='block';
	      }
	      else{
	        document.getElementById(id).style.display='none';
	      }
	    });
			
}*/